<template>
  <div class="CourseHomeView">
    <ScrollableContainerWithStickyHeader
      ref="header"
      class="scrollContainer"
      :sticky-height="headerStickyHeight">
      <div
        slot="header"
        ref="imageContainer"
        class="imageContainer">
        <div
          class="image"
          :style="{ backgroundImage: `url(${course.bannerImage})`, backgroundColor: theme.color }" />
        <div class="course-header">
          <div class="course-number">
            {{ courseNumber }}
          </div>
          <div class="course-name">
            {{ course.title }}
          </div>
          <div class="course-progress">
            {{ courseProgressText }}
          </div>
        </div>
       
      </div>

      <div slot="body">
        <DashboardWidthWrapper>
          <DashboardCourseDetails
            :course-id="courseId"
            show-image
            hide-title />
        </DashboardWidthWrapper>
      </div>
    </ScrollableContainerWithStickyHeader>

    <DashboardTopBar
      ref="topbar"
      class="topbar"
      :xtransparent="true"
      theme="dark-bg" />
  </div>
</template>

<translations>
    completed: '{coursePercentageProgress} Completed'
    completed_no: '{coursePercentageProgress}  Fullført'        
</translations>

<script>
import Color from 'color';
import ThemeMixin from '../theme-mixin';

import DashboardCourseDetails from '@/modules/dashboard/components/DashboardCourseDetails';
import DashboardWidthWrapper from '@/modules/dashboard/components/DashboardWidthWrapper';
import DashboardTopBar from '@/modules/dashboard/components/DashboardTopBar';
import ScrollableContainerWithStickyHeader from '@/components/ScrollableContainerWithStickyHeader';
import { mapGetters } from 'vuex';

export default {
    components: {
        ScrollableContainerWithStickyHeader,
        DashboardCourseDetails,
        DashboardWidthWrapper,
        DashboardTopBar,
    },
    mixins: [ThemeMixin],
    props: {
        courseId: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            getJourneyById: 'moduleCourse/getJourneyById',
            getStepById: 'moduleCourse/getStepById'
        }),

        course() {
            return this.$store.state.moduleCourse.courses[this.courseId];
        },
        headerColor() {
            let color = this.theme.color;
            return Color(color).darken(0.6);
        },

        courseNumber() {
            const splittedId = this.courseId.split('-');

            return splittedId[0].charAt(0).toUpperCase() + splittedId[0].slice(1) + ' ' + splittedId[1];
        },


        courseStats() {
            return this.$store.getters['moduleCourse/getCourseStats'](this.courseId);
            
        },
        getCourseProgress(){
            return (this.courseStats.numberOfDoneSteps / this.courseStats.numberOfSteps) * 100 + '%';
        },

        courseProgressText() {
            return this.$t('completed', { coursePercentageProgress: this.getCourseProgress });
        }
    },

    mounted() {
    },

    methods: {
        headerStickyHeight() {
            if (this.$refs.topbar) {
                return this.$refs.topbar.$el.clientHeight; //  + this.$refs.tabsSection.clientHeight
            } else {
                return 0;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
$backgroundColor: linear-gradient(180deg, #0a1335 0%, #1e1456 93.87%);

.course-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;   

    .course-number {
        background: #dddddd;
        border-radius: 6px;
        width: 4em;
        height: 1.5em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .course-name {
        color: #ffffff;
        font-weight: 500;
        font-size: 48px;
        @media (max-width: 560px) {
            font-size: 36px;
        }
        @media (max-width: 376px) {
            font-size: 24px;
        }
    }

    .course-progress {
        color: #bcbcbc;
        font-size: 16px;
    }
}

.CourseHomeView {
    background-image: $backgroundColor;
}

.header {
    background-color: green;
    height: $topBarHeight; //10em;
}

.scrollContainer {
    height: 100%;
}

.learnMore {
    text-decoration: underline;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -2em; // NOTE: Make image extend out of page. Looks nicer with Safari elastic over-scroll
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: black;
}

.image {
    position: absolute;
    $offset: 0; // NOTE: 15px needed here if we blur the image
    top: -$offset;
    right: -$offset;
    bottom: -$offset;
    left: -$offset;
    transform: translate3d(0, 0, 0); // NOTE: Needed in conjuction with the filter effect in Safari, for
    background-position: center;
    background-size: cover;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DashboardCourseDetails {
    padding-top: 0;
    margin-top: 3em;
}

.topbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
</style>
